<template>
  <v-container id="testimonials" tag="section" class="container">
    <v-row align="center">
      <v-col cols="12" md="6">
        <label class="display-1 font-weight-bold mb-3 text-center">
          Explore the experiences of my valued clients
        </label>
      </v-col>
      <v-col cols="12" md="6">
        <v-carousel :continuous="true" :cycle="true" :show-arrows="true" hide-delimiter-background
          delimiter-icon="mdi-minus" height=auto>
          <v-carousel-item v-for="(testimonial, i) in testimonials" :key="i">
            <v-card :color="colors[i]" height="100%" tile>
              <div class="star">
                <v-icon>mdi-star</v-icon>
                <v-icon>mdi-star</v-icon>
                <v-icon>mdi-star</v-icon>
                <v-icon>mdi-star</v-icon>
                <v-icon>mdi-star</v-icon>
              </div>
              <v-card-text class="text-h5 temp">{{ testimonial.comment }}</v-card-text>
              <v-card-subtitle class="text-h6">{{ testimonial.author }}</v-card-subtitle>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Testimonials',
  data: () => ({
    testimonials: [
      {
        comment: 'Working with Mahsa on purchasing our first home was a delightful experience. Her warmth and expertise, coupled with unmatched organizational skills, truly set her apart. She navigated us through the complexities with ease and genuine care, ensuring we felt confident and supported every step of the way. We are immensely grateful for her guidance and dedication.',
        author: 'L.B',
      },
      {
        comment: 'Mahsa was the best person to partner with for such an important decision as it is buying your first home. I 100% recommend her services, as she provides support in a timely manner, clarity in next steps and understands the needs from the beginning to make sure you have a successful transaction.',
        author: 'S.F',
      },
      {
        comment: 'Working with Mahsa as our realtor to sell our home was an exceptional experience from beginning to end. She provided us with a comprehensive education on the home selling process, ensuring we were fully informed at every step. Mahsa\'s expertise and dedication not only met but exceeded our expectations, securing a sale price that surpassed what we had imagined possible for our home. Beyond achieving an excellent price, she negotiated terms that offered us peace of mind and the flexibility we desired for planning our future steps. Her professionalism and commitment to our needs made the entire process seamless and highly rewarding.',
        author: 'M.G',
      },
      {
        comment: 'Mahsa was a pleasure to work with. She’s so kind and friendly and took the time to get to know my needs and what I was looking for. Overall, Mahsa was excellent at communicating with us and she took her time to answer all my questions in a timely manner and help me throughout the whole process. At the end I found exactly what I was looking for! Would definitely recommend her to everyone I know!',
        author: 'S.A',
      },
      {
        comment: 'As our real estate agent Mahsa was...well what can I say-exceptional! This rental transaction was long and tedious. Mahsa helped guide us through the process with patience and continuous support and hard work.  It was like dealing with a friend.  We will definitely continue to use Mahsa as our agent in any future real estate transactions. She has earned our trust and confidence in her abilities.',
        author: 'K.D and D.N',
      },
    ],
    colors: [
      'rgb(249, 249, 249)',
      'rgb(249, 249, 249)',
      'rgb(249, 249, 249)',
      'rgb(249, 249, 249)',
      'rgb(249, 249, 249)',
    ],
  }),
}
</script>

<style scoped>
.star {
  padding-left: 10px;
}

.star i {
  color: #fab115;
  font-size: 2.5em;
}

.container {
  background-color: #F9F9F9;
  padding: 20px;
  margin-top: 10px;
}

::v-deep .v-carousel .v-window__prev,
::v-deep .v-carousel .v-window__next {
  top: 0 !important;
  /* Force the buttons to the top */
}

::v-deep .v-carousel .v-window__prev {
  /* Space between the two arrows */
  left: 89% !important;
}

::v-deep .v-carousel .v-window__next {
  right: 0 !important;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .display-1 {
    font-size: 1.5rem !important;
    line-height: 2rem;
  }

  .text-h5 {
    font-size: 1rem !important;
  }

  .text-h6 {
    font-size: 0.9rem !important;
  }

  .star i {
    font-size: 1.5em;
  }

  ::v-deep .v-carousel .v-window__prev {
    /* Space between the two arrows */
    left: 70% !important;
  }

}</style>
